<template>
  <div class="field-row">
    <div class="flex-fill">
      <multiselect
        class="form-control"
        v-model="responsible"
        label="name"
        track-by="id"
        :options="people"
        :showLabels="false"
        :allowEmpty="true"
        :disabled="disabled"
        placeholder="Responsável..."
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template #singleLabel="props">
          <div class="person-name">
            {{props.option.name}}
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="person-option">
            <Avatar size="30px" :src="props.option.picture" />
            <div>{{ props.option.name }}</div>
          </div>
        </template>

        <template slot="noOptions">
          Nenhuma opção
        </template>

        <template slot="noResult">
          Nenhum resultado
        </template>
      </multiselect>
    </div>
  </div>

</template>
<script>

import {getCurrentUser} from "@/utils/localStorageManager"
export default {
  name: "SimpleClinicPersonSelect",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Avatar: () => import('@/assets/icons/avatar.svg'),
  },
  props: {
    value: Object,
    clinic_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      people: [],
      responsible: null,
      disabled: false
    }
  },
  mounted() {
    this.getClinicStaff()
    const user = getCurrentUser()
    this.responsible = {
      id: user.id,
      name: user.name,
      role: user.role,
      user_id: user.user_id
    }
    this.value.responsible_clinic_person_id = this.responsible
    this.disabled = true
  },

  methods: {
    /**
     * Get clinic staff list to populate the People Select component
     */
    getClinicStaff() {
      this.api.getPeopleFromClinic(this.clinic_id)
      .then(({ data }) => {
        this.people = data.map(person => ({
          id: person.id,
          user_id: person.user_id,
          name: person.name,
          role: person.role,
        }))
      })
      .catch(err => {
        this.$toast.error('Erro ao listar Colaboradores da Clínica. Por favor, atualize o sistema. Se o problema persistir, contate o Suporte e relate a Ocorrência')
        console.error(err.message)
      })
    },
  },
  watch: {

  }
}
</script>

<style lang="scss">
.people-combo {
  border: 1px solid var(--neutral-300) !important;
  min-height: 38px;
}
.field-row {
  margin-bottom: 10px;

  .form-control {
    padding: 0;
  }
  .multiselect {
    border: 1px solid #C6CEEB;
    border-radius: 8px;
    height: 38px;

    .multiselect__tags{
      height: 38px;
    }

    .multiselect__single {
      font-size: 16px;
      font-weight: 400;

      .doctor-name {
        display: flex;
        align-items: center;
        color: #525C7A;
        font-size: 16px;
        font-weight: 650;
      }
    }

    .multiselect__option {
      svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      .doctor-option {
        display: flex;
        align-items: center;
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #C6CEEB;
      border-radius: 100px;
    }

    &.multiselect--disabled {
      opacity: 1;

      .multiselect__select {
        background-color: #ffffff;
      }
    }
  }

  .flex-fill {

    input.form-control {
      height: 38px !important;
    }

  }
}
</style>

<style scoped lang="scss">
 .option__title {
   padding: 7px 10px;
   border-radius: 15px;
 }
</style>